import React from 'react'
import { graphql } from 'gatsby'
import { motion } from "framer-motion"
import { fadeSlow, revealInOut } from "../helpers/transitionHelper"
import BlockBody from '../components/BlockBody';
import {Helmet} from "react-helmet";
import urlFor from '../helpers/imageBuilder'

export const query = graphql`
  query ResearcherQuery($id: String!) {
    sanitySiteSettings {
      title
    }
    post: sanityPeople(id: { eq: $id }) {
      id
      name
      slug {
        current
      }
      shortCv
      _rawLongCv
      links {
        link
        title
      }
      _rawPortrait
    }
  }
`

const ResearcherTemplate = props => {
  const { data } = props
  const person = data && data.post
  return (
    <motion.section
      initial="initial"
      animate="enter"
      exit="exit"
    >    
      <Helmet>
        <title>{data.post.name} - {data.sanitySiteSettings.title}</title>
      </Helmet>
      <motion.div variants={fadeSlow}>
         <motion.div className="researcher-page" variants={{
          enter: { transition: { 
            staggerChildren: .1,
            delayChildren: props.childAnimationDelay,
          }}
        }}>
          <motion.img variants={revealInOut} src={urlFor(person._rawPortrait).width(700).height(800).url()} alt={person.name}/>
          <motion.div className>
            <motion.h1 variants={revealInOut} className="page-title post-title">{person.name}</motion.h1>

            <motion.div className="person-cv" variants={revealInOut}>
              <BlockBody blocks={person._rawLongCv}/>
            </motion.div>

            <motion.div className="person_links" variants={{
                enter: { transition: { 
                  staggerChildren: .1,
                }}
              }}>
              {person.links.map((link) => (
                <a key={link.linkText} href={link.link} target="_blank" rel="noreferrer">
                  <span>{link.title}</span>
                  <span role="img" aria-label="link">🔗</span>
                </a>
              ))}
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.section>
  )
}

export default ResearcherTemplate