import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import getYouTubeID from 'get-youtube-id'

import urlFor from '../helpers/imageBuilder'

const serializers = {
  types: {
    youtube (props) {
      var id = getYouTubeID(props.node.url);
      return (
      <div class="youtube-embed iframe-wrapper">
        <iframe title={id} class="responsive-iframe" src={`https://www.youtube.com/embed/${id}`}></iframe>
      </div>
      )
    },
    image (props) {
      return (
        <img src={urlFor(props.node).width(1200).url()} alt={props.node.alt}/>
      )
    }
  }
}

const BlockBody = ({blocks}) => (<BlockContent blocks={blocks} serializers={serializers}/>)

export default BlockBody